@font-face {
    font-family: 'Avantt';
    src: url('Avantt-Bold.woff2') format('woff2'),
        url('Avantt-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-ExtraBold.woff2') format('woff2'),
        url('Avantt-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-Heavy.woff2') format('woff2'),
        url('Avantt-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-BoldItalic.woff2') format('woff2'),
        url('Avantt-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-ExtraBoldItalic.woff2') format('woff2'),
        url('Avantt-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-HeavyItalic.woff2') format('woff2'),
        url('Avantt-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-SemiBoldItalic.woff2') format('woff2'),
        url('Avantt-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-Regular.woff2') format('woff2'),
        url('Avantt-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-Medium.woff2') format('woff2'),
        url('Avantt-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-SemiBold.woff2') format('woff2'),
        url('Avantt-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-MediumItalic.woff2') format('woff2'),
        url('Avantt-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-ThinItalic.woff2') format('woff2'),
        url('Avantt-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-LightItalic.woff2') format('woff2'),
        url('Avantt-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-Light.woff2') format('woff2'),
        url('Avantt-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-RegularItalic.woff2') format('woff2'),
        url('Avantt-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avantt';
    src: url('Avantt-Thin.woff2') format('woff2'),
        url('Avantt-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

